/*
Template Name: Skote - Admin & Dashboard Template
Author: Themesbrand
Version: 3.1.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/


//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";

// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/dragula";
@import "custom/plugins/session-timeout";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/rating";
@import "custom/plugins/toastr";
@import "custom/plugins/parsley";
@import "custom/plugins/select2";

@import "custom/plugins/switch";
@import "custom/plugins/colorpicker";
@import "custom/plugins/timepicker";
@import "custom/plugins/datepicker";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/form-editors";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
@import "custom/plugins/datatable";
@import "custom/plugins/responsive-table";
@import "custom/plugins/table-editable";
@import "custom/plugins/apexcharts";
@import "custom/plugins/echarts";
@import "custom/plugins/flot";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/google-map";
@import "custom/plugins/vector-maps";
@import "custom/plugins/leaflet-maps";


// Pages
@import "custom/pages/authentication";
@import "custom/pages/ecommerce";
@import "custom/pages/email";
@import "custom/pages/file-manager";
@import "custom/pages/chat";
@import "custom/pages/projects";
@import "custom/pages/contacts";
@import "custom/pages/crypto";
@import "custom/pages/coming-soon";
@import "custom/pages/timeline";
@import "custom/pages/extras-pages";



// .MuiTableRow-root:nth-child(odd){
//     background-color: #f8f9fa;
// }
// .MuiTableCell-head{
//     font-weight: 700 !important;
// }
// p.css-levciy-MuiTablePagination-displayedRows{
//     margin-bottom: 0;
// }
body{
    padding-right: 0 !important;
}
.ag-theme-alpine .ag-row-even:not(.ag-row-selected){
    background-color: #f0f0f0 !important;
}
.ag-theme-alpine .ag-row-even:not(.ag-row-selected):hover{
    background-color: rgba(33,150,243,0.1) !important;
}
.ag-theme-alpine .ag-root-wrapper{
    border: 0px !important;
}
.ag-theme-alpine .ag-header{
    border: 0px !important;
}
.ag-theme-alpine .ag-paging-panel{
    border: 0px !important;
}
.ag-theme-alpine .ag-cell-inline-editing {
    height: 100% !important;
}
.ag-theme-alpine .ag-cell-inline-editing .ag-react-container input{
    margin-top: 0.65em;
}
.ag-theme-alpine .ag-popup-editor{
    margin-top: 0.6em;
}


// .ag-theme-alpine-dark .ag-root-wrapper-body{
//     background-color: #2a3042;
// }
// .ag-theme-alpine-dark .ag-header-row, .ag-theme-alpine-dark .ag-paging-panel{
//     background-color: #2a3042;
// }
// .ag-theme-alpine-dark .ag-row-even:not(.ag-row-selected){
//     background-color: #434c69 !important;
// }
// .ag-theme-alpine-dark .ag-row-odd:not(.ag-row-selected){
//     background-color: #373e54 !important;
// }
// .ag-theme-alpine-dark .ag-row-even:not(.ag-row-selected):hover{
//     background-color: rgba(33,150,243,0.1) !important;
// }
// .ag-theme-alpine-dark .ag-row-odd:not(.ag-row-selected):hover{
//     background-color: rgba(33,150,243,0.1) !important;
// }
// .ag-theme-alpine-dark .ag-root-wrapper{
//     border: 0px !important;
// }
.ag-theme-alpine-dark .ag-header{
    border: 0px !important;
}
.ag-theme-alpine-dark .ag-paging-panel{
    border: 0px !important;
}
.ag-theme-alpine-dark .ag-cell-inline-editing {
    height: 100% !important;
}
.ag-theme-alpine-dark .ag-cell-inline-editing .ag-react-container input{
    margin-top: 0.65em;
}
.ag-theme-alpine-dark .ag-popup-editor{
    margin-top: 0.6em;
}
@font-face {
    font-family: "faruma";
    src: url("../fonts/faruma.otf")
}

.dv {
    font-family: "faruma";
}
label, p, h1, h2, h3, h4, h5, button, a, li{
    font-family: 'faruma' !important;
}
input{
    direction: ltr;
}
input.dv{
    font-family: 'faruma' !important;
    font-size: 1.2em !important;
    direction: rtl;
}
label, button, a, li{
    font-size: 1.2em !important;
}
h5{
    font-size: 1.5em !important;
}
.en {
    font-family: Arial, Helvetica, sans-serif !important;
    text-align: left;
    direction: ltr;
}
.dvl{
    font-family: 'faruma' !important;
    font-size: 1.2em !important;
    direction: rtl;
}
.dvxl{
    font-family: 'faruma' !important;
    font-size: 1.4em !important;
    direction: rtl;
}
.dv .ag-header-coll-text{
    font-family: 'Faruma';
    font-size: 1.2em !important;
}
.toast-dhivehi{
    font-family: 'faruma' !important;
    font-size: 1.35em !important;
    direction: rtl;
}
.tal{
    text-align: left;
}
.enf{
    font-family: Arial, Helvetica, sans-serif !important;
    direction: ltr;
}
.enf::placeholder{
    text-align: left !important;
    direction: ltr !important;
}

.MuiPaper-root{
    padding-left: 1.2em;
    padding-right: 1.2em;
}
.MuiPaper-root span{
    font-size: 1.1em !important;
}
.flip-icon::before{
    transform: scaleX(-1);
}
.failed-message{
    background-color: #D64045 !important;
    color: white;
}
.failed-message .conversation-name{
    color: white !important;
}
.select2-selection__placeholder{
    padding-right:1em;
}

.dark-case-viewer .step-title{
    color: white;
}
.dark-case-viewer .tab-pane .row li{
    color: white;
}
.dark-case-viewer .card-title{
    color: white;
}
.dark-case-viewer .case-viewer b{
    color: #d6d6d6;
}
.dark-case-viewer{
    background-color: #171a28;
}
.dark-case-viewer .tox-editor-header{
    background-color: #171a28 !important;
}
.dark-case-viewer .tox-toolbar{
    background-color: #cad0e9 !important;
}
.dark-case-viewer .tox.tox-tinymce{
    border: 1px solid #171a28 !important;
}
.modal-body.dark-modal{
    background-color: #171a28 !important;
}
.modal-header.dark-modal{
    border: 1px solid #171a28;
}
.dark-case-viewer .page-content{
    background-color: #171a28;
}
.dark-case-viewer .card-body, .dark-case-viewer .card{
    background-color: #10131d;
}
.dark-case-viewer h1, .dark-case-viewer h2, .dark-case-viewer h3, .dark-case-viewer h4 .dark-case-viewer h5, .dark-case-viewer h6, .dark-case-viewer p, .dark-case-viewer label{
    color: white;
}
.dark .dark-bg{
    background-color: #171a28;
}